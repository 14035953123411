import { Box, Button, Grid } from '@mui/material'
import React, { useState } from 'react'
import Sidebar from '../SideBar/Sidebar'
import './Statistics.css'
import Switch from '@mui/material/Switch';
import { styled, useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
// import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import undo from '../../../Images/undo.svg';
import root from '../../../Images/root.svg'
import chart from '../../../Images/chart.svg'
import fullScreen from '../../../Images/full-screen.svg';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StatsChart from './StatsChart';
import StatsGoogleMap from './StatsGoogleMap';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// import 'bootstrap/dist/css/bootstrap.min.css';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 480,
    position: 'relative',
    bgcolor: '#fff',
    // border: '2px solid #000',
    // boxShadow: 24,
    borderRadius: '12px',
    // boxShadow: '12px 27px 34.6px - 9px rgba(0, 0, 0, 0.10)',
    p: 2,
};

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#dfca6f',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function Statistics() {
    const theme = useTheme();

    const isLgup = useMediaQuery(theme.breakpoints.up('lg'));
    const mobileUp = useMediaQuery(theme.breakpoints.up('sm'));

    const [age, setAge] = useState(10);

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const [open, setOpen] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const navigate = useNavigate()


    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className='dashboard applicant-full statistic-body'>
            <Box sx={{ display: isLgup ? 'flex' : 'block' }}>
                {/* <CssBaseline /> */}
                <Sidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: '80px' }}>
                    <Box className="action-tab display-2">
                        <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
                            <Tab label="Statistics" {...a11yProps(0)} />
                            <Tab label="Analytics" {...a11yProps(1)} />
                        </Tabs>
                        {/* <div className='apllicant-direct'>
                            <Link to="/newapplicant"> <AddIcon /> New applicant</Link>
                        </div> */}
                    </Box>
                    <CustomTabPanel value={value} index={0} className="statistic-tab">
                        <div className="margin-top">

                            <div className="margin-top">
                                <div className="getstar display-1">
                                    Overview
                                </div>
                            </div>
                            <div className="margin-top">
                                <div className="display-1 bor" style={{ flexWrap: 'wrap' }}>
                                    <div className="display-1">
                                        <div className="prd">Period:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={10}>from Aug 5, 2024 to Aug 6, 2024</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="display-1">
                                        <div className="prd">Show:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={10}>Last 24 hours</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="display-1 ">
                                        <div className="prd">Level name:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}

                                                >
                                                    <MenuItem value={10} className='select-text'>All</MenuItem>
                                                    <MenuItem value={20} className='select-text'>Twenty</MenuItem>
                                                    <MenuItem value={30} className='select-text'>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="display-1">
                                        <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                        <div className="prodtin">Level breakdown</div>
                                    </div>

                                </div>
                            </div>

                            <div className="margin-top bor">
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Checked Applicants</TableCell>
                                                <TableCell align="right">Answers</TableCell>
                                                <TableCell align="right">Countries</TableCell>
                                                <TableCell align="right">Rejection Labels</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>

                                            <TableRow

                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>
                                                    <div className='check_app_first_child'>
                                                        <div className='statistic-bold-td'>1</div>
                                                        <div className='inner-thead-th'>Median Processing Time</div>
                                                        <div className='statistic-bold-td'>24 sec</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right"></TableCell>
                                                {/* <TableCell align="right" colspan="5" className='text-center ht'>No data yet</TableCell> */}
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <div className='emptyChartOuter'>
                                    <div className='inner-thead-th'>Checked Applicants (over time)</div>
                                    <div className='emptyChartDiv'>
                                        <div className='chartAction display-4'>
                                            <Button><img src={undo} /> </Button>
                                            <Button><img src={root} />  </Button>
                                            <Button><img src={chart} /></Button>
                                            <Button> <img src={fullScreen} /> </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="margin-top">

                            <div className="margin-top">
                                <div className='display-2'>
                                    <div className="getstar display-1">
                                        Applicants Reviewed <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.91016 15C10.7667 15 12.5471 14.2625 13.8599 12.9497C15.1727 11.637 15.9102 9.85652 15.9102 8C15.9102 6.14348 15.1727 4.36301 13.8599 3.05025C12.5471 1.7375 10.7667 1 8.91016 1C7.05364 1 5.27316 1.7375 3.96041 3.05025C2.64765 4.36301 1.91016 6.14348 1.91016 8C1.91016 9.85652 2.64765 11.637 3.96041 12.9497C5.27316 14.2625 7.05364 15 8.91016 15ZM7.94316 8.783V9.126H9.28516V8.848C9.27745 8.68833 9.32165 8.53045 9.41116 8.398C9.49916 8.284 9.70516 8.118 10.0292 7.898C10.5012 7.589 10.8222 7.311 10.9902 7.064C11.1592 6.816 11.2432 6.523 11.2432 6.183C11.2432 5.673 11.0372 5.265 10.6262 4.959C10.2182 4.653 9.66616 4.5 8.97116 4.5C8.13808 4.49763 7.31677 4.69661 6.57716 5.08L7.12816 6.095C7.77616 5.792 8.35316 5.64 8.85916 5.64C9.14916 5.64 9.37616 5.693 9.53816 5.798C9.61584 5.84596 9.67932 5.91379 9.72203 5.99448C9.76475 6.07516 9.78516 6.16579 9.78116 6.257C9.78116 6.437 9.72316 6.598 9.60816 6.744C9.49816 6.889 9.26416 7.077 8.91016 7.309C8.54216 7.557 8.29016 7.789 8.15016 8.009C8.00829 8.24178 7.93643 8.51048 7.94316 8.783ZM8.00916 10.123C7.85716 10.256 7.78116 10.453 7.78116 10.712C7.78116 10.962 7.85916 11.157 8.01416 11.296C8.16916 11.432 8.38916 11.5 8.67216 11.5C8.94916 11.5 9.16516 11.43 9.32016 11.291C9.47516 11.149 9.55316 10.956 9.55316 10.711C9.55316 10.459 9.47516 10.264 9.32016 10.128C9.16816 9.992 8.95216 9.924 8.67216 9.924C8.38216 9.924 8.16116 9.989 8.00916 10.123Z" fill="#7D8799" />
                                        </svg>
                                    </div>
                                    <Button className="csvDownloadBtn display-1"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3391 12.8016V14.4016H2.53906V12.8016H15.3391ZM9.73906 1.60156V8.46856L11.5731 6.63556L12.7051 7.76756L8.93906 11.5316L5.17306 7.76756L6.30506 6.63556L8.13906 8.46856V1.60156H9.73906Z" fill="#373D4D" />
                                    </svg>
                                        Download CSV</Button>
                                </div>
                            </div>
                            <div className="margin-top">
                                <div className="display-1 bor" style={{ flexWrap: 'wrap' }}>
                                    <div className="display-1">
                                        <div className="prd">Period:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={10}>from Aug 5, 2024 to Aug 6, 2024</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="display-1">
                                        <div className="prd">Show:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={10}>Last 24 hours</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="display-1 ">
                                        <div className="prd">Breakdown by:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}

                                                >
                                                    <MenuItem value={10} className='select-text'>Total</MenuItem>
                                                    <MenuItem value={20} className='select-text'>Twenty</MenuItem>
                                                    <MenuItem value={30} className='select-text'>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="display-1">
                                        <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                        <div className="prodtin">UTC</div>
                                    </div>

                                </div>
                            </div>

                            <div className="bor">

                                <div className='emptyChartOuter mt-0  p-0'>
                                    {/* <div className='inner-thead-th'>Checked Applicants (over time)</div> */}
                                    <div className='emptyChartDiv'>
                                        <div className='chartAction display-4'>
                                            <Button><img src={undo} /> </Button>
                                            <Button><img src={root} />  </Button>
                                            <Button><img src={chart} /></Button>
                                            <Button> <img src={fullScreen} /> </Button>
                                        </div>
                                    </div>
                                    <div className='total-count'>Total 2</div>
                                </div>
                            </div>
                        </div>
                        <div className="margin-top">

                            <div className="margin-top">
                                <div className='display-2'>
                                    <div className="getstar display-1">
                                        Applicants Created <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.91016 15C10.7667 15 12.5471 14.2625 13.8599 12.9497C15.1727 11.637 15.9102 9.85652 15.9102 8C15.9102 6.14348 15.1727 4.36301 13.8599 3.05025C12.5471 1.7375 10.7667 1 8.91016 1C7.05364 1 5.27316 1.7375 3.96041 3.05025C2.64765 4.36301 1.91016 6.14348 1.91016 8C1.91016 9.85652 2.64765 11.637 3.96041 12.9497C5.27316 14.2625 7.05364 15 8.91016 15ZM7.94316 8.783V9.126H9.28516V8.848C9.27745 8.68833 9.32165 8.53045 9.41116 8.398C9.49916 8.284 9.70516 8.118 10.0292 7.898C10.5012 7.589 10.8222 7.311 10.9902 7.064C11.1592 6.816 11.2432 6.523 11.2432 6.183C11.2432 5.673 11.0372 5.265 10.6262 4.959C10.2182 4.653 9.66616 4.5 8.97116 4.5C8.13808 4.49763 7.31677 4.69661 6.57716 5.08L7.12816 6.095C7.77616 5.792 8.35316 5.64 8.85916 5.64C9.14916 5.64 9.37616 5.693 9.53816 5.798C9.61584 5.84596 9.67932 5.91379 9.72203 5.99448C9.76475 6.07516 9.78516 6.16579 9.78116 6.257C9.78116 6.437 9.72316 6.598 9.60816 6.744C9.49816 6.889 9.26416 7.077 8.91016 7.309C8.54216 7.557 8.29016 7.789 8.15016 8.009C8.00829 8.24178 7.93643 8.51048 7.94316 8.783ZM8.00916 10.123C7.85716 10.256 7.78116 10.453 7.78116 10.712C7.78116 10.962 7.85916 11.157 8.01416 11.296C8.16916 11.432 8.38916 11.5 8.67216 11.5C8.94916 11.5 9.16516 11.43 9.32016 11.291C9.47516 11.149 9.55316 10.956 9.55316 10.711C9.55316 10.459 9.47516 10.264 9.32016 10.128C9.16816 9.992 8.95216 9.924 8.67216 9.924C8.38216 9.924 8.16116 9.989 8.00916 10.123Z" fill="#7D8799" />
                                        </svg>
                                    </div>
                                    <Button className="csvDownloadBtn display-1"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3391 12.8016V14.4016H2.53906V12.8016H15.3391ZM9.73906 1.60156V8.46856L11.5731 6.63556L12.7051 7.76756L8.93906 11.5316L5.17306 7.76756L6.30506 6.63556L8.13906 8.46856V1.60156H9.73906Z" fill="#373D4D" />
                                    </svg>
                                        Download CSV</Button>
                                </div>
                            </div>
                            <div className="margin-top">
                                <div className="display-1 bor" style={{ flexWrap: 'wrap' }}>
                                    <div className="display-1">
                                        <div className="prd">Period:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={10}>from Aug 5, 2024 to Aug 6, 2024</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="display-1">
                                        <div className="prd">Show:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={10}>Last 24 hours</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="display-1 ">
                                        <div className="prd">Breakdown by:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}

                                                >
                                                    <MenuItem value={10} className='select-text'>Total</MenuItem>
                                                    <MenuItem value={20} className='select-text'>Twenty</MenuItem>
                                                    <MenuItem value={30} className='select-text'>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="display-1">
                                        <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                        <div className="prodtin">UTC</div>
                                    </div>

                                </div>
                            </div>

                            <div className="bor">

                                <div className='emptyChartOuter mt-0  p-0'>
                                    {/* <div className='inner-thead-th'>Checked Applicants (over time)</div> */}
                                    <div className='emptyChartDiv'>
                                        <div className='chartAction display-4'>
                                            <Button><img src={undo} /> </Button>
                                            <Button><img src={root} />  </Button>
                                            <Button><img src={chart} /></Button>
                                            <Button> <img src={fullScreen} /> </Button>
                                        </div>
                                    </div>
                                    <div className='total-count'>Total 4</div>
                                </div>
                            </div>
                        </div>
                        <div className="margin-top">

                            <div className="margin-top">
                                <div className='display-2'>
                                    <div className="getstar display-1">
                                        Applicant Documents Added <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.91016 15C10.7667 15 12.5471 14.2625 13.8599 12.9497C15.1727 11.637 15.9102 9.85652 15.9102 8C15.9102 6.14348 15.1727 4.36301 13.8599 3.05025C12.5471 1.7375 10.7667 1 8.91016 1C7.05364 1 5.27316 1.7375 3.96041 3.05025C2.64765 4.36301 1.91016 6.14348 1.91016 8C1.91016 9.85652 2.64765 11.637 3.96041 12.9497C5.27316 14.2625 7.05364 15 8.91016 15ZM7.94316 8.783V9.126H9.28516V8.848C9.27745 8.68833 9.32165 8.53045 9.41116 8.398C9.49916 8.284 9.70516 8.118 10.0292 7.898C10.5012 7.589 10.8222 7.311 10.9902 7.064C11.1592 6.816 11.2432 6.523 11.2432 6.183C11.2432 5.673 11.0372 5.265 10.6262 4.959C10.2182 4.653 9.66616 4.5 8.97116 4.5C8.13808 4.49763 7.31677 4.69661 6.57716 5.08L7.12816 6.095C7.77616 5.792 8.35316 5.64 8.85916 5.64C9.14916 5.64 9.37616 5.693 9.53816 5.798C9.61584 5.84596 9.67932 5.91379 9.72203 5.99448C9.76475 6.07516 9.78516 6.16579 9.78116 6.257C9.78116 6.437 9.72316 6.598 9.60816 6.744C9.49816 6.889 9.26416 7.077 8.91016 7.309C8.54216 7.557 8.29016 7.789 8.15016 8.009C8.00829 8.24178 7.93643 8.51048 7.94316 8.783ZM8.00916 10.123C7.85716 10.256 7.78116 10.453 7.78116 10.712C7.78116 10.962 7.85916 11.157 8.01416 11.296C8.16916 11.432 8.38916 11.5 8.67216 11.5C8.94916 11.5 9.16516 11.43 9.32016 11.291C9.47516 11.149 9.55316 10.956 9.55316 10.711C9.55316 10.459 9.47516 10.264 9.32016 10.128C9.16816 9.992 8.95216 9.924 8.67216 9.924C8.38216 9.924 8.16116 9.989 8.00916 10.123Z" fill="#7D8799" />
                                        </svg>
                                    </div>
                                    <Button className="csvDownloadBtn display-1"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3391 12.8016V14.4016H2.53906V12.8016H15.3391ZM9.73906 1.60156V8.46856L11.5731 6.63556L12.7051 7.76756L8.93906 11.5316L5.17306 7.76756L6.30506 6.63556L8.13906 8.46856V1.60156H9.73906Z" fill="#373D4D" />
                                    </svg>
                                        Download CSV</Button>
                                </div>
                            </div>
                            <div className="margin-top">
                                <div className="display-1 bor" style={{ flexWrap: 'wrap' }}>
                                    <div className="display-1">
                                        <div className="prd">Period:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={10}>from Aug 5, 2024 to Aug 6, 2024</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="display-1">
                                        <div className="prd">Show:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={10}>Last 24 hours</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="display-1 ">
                                        <div className="prd">Breakdown by:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}

                                                >
                                                    <MenuItem value={10} className='select-text'>Total</MenuItem>
                                                    <MenuItem value={20} className='select-text'>Twenty</MenuItem>
                                                    <MenuItem value={30} className='select-text'>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="display-1">
                                        <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                        <div className="prodtin">UTC</div>
                                    </div>

                                </div>
                            </div>

                            <div className="bor">

                                <div className='emptyChartOuter mt-0  p-0'>
                                    {/* <div className='inner-thead-th'>Checked Applicants (over time)</div> */}
                                    <div className='emptyChartDiv'>
                                        <div className='chartAction display-4'>
                                            <Button><img src={undo} /> </Button>
                                            <Button><img src={root} />  </Button>
                                            <Button><img src={chart} /></Button>
                                            <Button> <img src={fullScreen} /> </Button>
                                        </div>
                                    </div>
                                    <div className='total-count'>Total 10</div>
                                </div>
                            </div>
                        </div>
                        <div className="margin-top">

                            <div className="margin-top">
                                <div className='display-2'>
                                    <div className="getstar display-1">
                                        Logins
                                    </div>
                                    <Button className="csvDownloadBtn display-1"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3391 12.8016V14.4016H2.53906V12.8016H15.3391ZM9.73906 1.60156V8.46856L11.5731 6.63556L12.7051 7.76756L8.93906 11.5316L5.17306 7.76756L6.30506 6.63556L8.13906 8.46856V1.60156H9.73906Z" fill="#373D4D" />
                                    </svg>
                                        Download CSV</Button>
                                </div>
                            </div>
                            <div className="margin-top">
                                <div className="display-1 bor" style={{ flexWrap: 'wrap' }}>
                                    <div className="display-1">
                                        <div className="prd">Period:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={10}>from Aug 5, 2024 to Aug 6, 2024</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="display-1">
                                        <div className="prd">Show:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={10}>Last 24 hours</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="display-1 ">
                                        <div className="prd">Breakdown by:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}

                                                >
                                                    <MenuItem value={10} className='select-text'>Total</MenuItem>
                                                    <MenuItem value={20} className='select-text'>Twenty</MenuItem>
                                                    <MenuItem value={30} className='select-text'>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="display-1">
                                        <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                        <div className="prodtin">UTC</div>
                                    </div>

                                </div>
                            </div>

                            <div className="bor">

                                <div className='emptyChartOuter mt-0  p-0'>
                                    {/* <div className='inner-thead-th'>Checked Applicants (over time)</div> */}
                                    <div className='emptyChartDiv'>
                                        <div className='chartAction display-4'>
                                            <Button><img src={undo} /> </Button>
                                            <Button><img src={root} />  </Button>
                                            <Button><img src={chart} /></Button>
                                            <Button> <img src={fullScreen} /> </Button>
                                        </div>
                                        <div className='nodata'>No data yet</div>

                                    </div>
                                    {/* <div className='total-count'>Total 2</div> */}
                                </div>
                            </div>
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1} className="statistic-tab analytics-tab">
                        <div className="margin-top">
                            <div className="display-2">
                                <div className="margin-top analytic-head">
                                    <div className="getstar display-1 analytic-head-theme">
                                        Requests received and checks completed
                                    </div>
                                    <span>5d88c6ea0a975a5c77cbe39e</span>
                                </div>
                                <div className='display-4'>
                                    <Button className="csvDownloadBtn copy"><ContentCopyIcon /></Button>
                                    <Button className="csvDownloadBtn"><VerticalAlignBottomIcon />Download CSV</Button>
                                </div>
                            </div>

                            <div className="margin-top">
                                <div className="display-1 bor" style={{ flexWrap: 'wrap' }}>
                                    <div className="display-1">
                                        <div className="prd">Period:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={10}>from Aug 5, 2024 to Aug 6, 2024</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="display-1">
                                        <div className="prd">Show:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={10}>Last 24 hours</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>

                                </div>
                            </div>

                            <div className="margin-top-bor">
                                <div className='emptyChartOuter mt-0 p-0'>
                                    {/* <div className='inner-thead-th'>Checked Applicants (over time)</div> */}
                                    <div className='emptyChartDiv'>
                                        <div className='chartAction display-4'>
                                            <Button><img src={undo} /> </Button>
                                            <Button><img src={root} />  </Button>
                                            <Button><img src={chart} /></Button>
                                            <Button> <img src={fullScreen} /> </Button>
                                        </div>

                                        <StatsChart />
                                        <div className='display-1'>
                                            <div className='total-count'>Сhecks requested 1</div>
                                            <div className='total-count bdr-blue'>Checks completed 2</div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="margin-top">
                            <div className="display-2">
                                <div className="margin-top analytic-head">
                                    <div className="getstar display-1 analytic-head-theme">
                                        Conversions
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.7207 15C10.5772 15 12.3577 14.2625 13.6705 12.9497C14.9832 11.637 15.7207 9.85652 15.7207 8C15.7207 6.14348 14.9832 4.36301 13.6705 3.05025C12.3577 1.7375 10.5772 1 8.7207 1C6.86419 1 5.08371 1.7375 3.77096 3.05025C2.4582 4.36301 1.7207 6.14348 1.7207 8C1.7207 9.85652 2.4582 11.637 3.77096 12.9497C5.08371 14.2625 6.86419 15 8.7207 15ZM7.7537 8.783V9.126H9.0957V8.848C9.088 8.68833 9.1322 8.53045 9.2217 8.398C9.3097 8.284 9.5157 8.118 9.8397 7.898C10.3117 7.589 10.6327 7.311 10.8007 7.064C10.9697 6.816 11.0537 6.523 11.0537 6.183C11.0537 5.673 10.8477 5.265 10.4367 4.959C10.0287 4.653 9.4767 4.5 8.7817 4.5C7.94863 4.49763 7.12732 4.69661 6.3877 5.08L6.9387 6.095C7.5867 5.792 8.1637 5.64 8.6697 5.64C8.9597 5.64 9.1867 5.693 9.3487 5.798C9.42639 5.84596 9.48986 5.91379 9.53258 5.99448C9.5753 6.07516 9.59571 6.16579 9.5917 6.257C9.5917 6.437 9.5337 6.598 9.4187 6.744C9.3087 6.889 9.0747 7.077 8.7207 7.309C8.3527 7.557 8.1007 7.789 7.9607 8.009C7.81884 8.24178 7.74698 8.51048 7.7537 8.783ZM7.8197 10.123C7.6677 10.256 7.5917 10.453 7.5917 10.712C7.5917 10.962 7.6697 11.157 7.8247 11.296C7.9797 11.432 8.1997 11.5 8.4827 11.5C8.7597 11.5 8.9757 11.43 9.1307 11.291C9.2857 11.149 9.3637 10.956 9.3637 10.711C9.3637 10.459 9.2857 10.264 9.1307 10.128C8.9787 9.992 8.7627 9.924 8.4827 9.924C8.1927 9.924 7.9717 9.989 7.8197 10.123Z" fill="#7D8799" />
                                        </svg>
                                    </div>
                                    <span>5d81017fdae8a862499bb5c6</span>
                                </div>
                                <div className='display-4'>
                                    <Button className="csvDownloadBtn copy"><ContentCopyIcon /></Button>
                                    <Button className="csvDownloadBtn"><VerticalAlignBottomIcon />Download CSV</Button>
                                </div>
                            </div>

                            <div className="margin-top">
                                <div className="display-1 bor" style={{ flexWrap: 'wrap' }}>
                                    <div className="display-1">
                                        <div className="prd">Period:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={10}>from Aug 5, 2024 to Aug 6, 2024</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>
                                    <div className="display-1">
                                        <div className="prd">Show:</div>
                                        <Box sx={{ minWidth: 120 }}>
                                            <FormControl fullWidth className='select-dash'>
                                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={age}
                                                    // label="Age"
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={10}>Last 24 hours</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </div>

                                </div>
                            </div>

                            <div className="margin-top-bor">
                                <div className='emptyChartOuter mt-0 p-0'>
                                    {/* <div className='inner-thead-th'>Checked Applicants (over time)</div> */}
                                    <div className='emptyChartDiv'>
                                        <div className='chartAction display-4'>
                                            <Button><img src={undo} /> </Button>
                                            <Button><img src={root} />  </Button>
                                            <Button><img src={chart} /></Button>
                                            <Button> <img src={fullScreen} /> </Button>
                                        </div>

                                        <StatsChart />
                                        <div className='display-1'>
                                            <div className='total-count'>% finished uploading 0.78</div>
                                            <div className='total-count bdr-blue'>% approved vs. all uploaded 3.13</div>
                                            <div className='total-count bdr-red'>% approved vs. started 0.78</div>
                                            <div className='total-count bdr-yellow'>% final declines 0</div>
                                            <div className='total-count bdr-violet'>% finished check after intent 0.78</div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="margin-top">
                            <div className="display-2">
                                <div className="margin-top analytic-head">
                                    <div className="getstar display-1 analytic-head-theme">
                                        Most common rejection reasons per country
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.7207 15C10.5772 15 12.3577 14.2625 13.6705 12.9497C14.9832 11.637 15.7207 9.85652 15.7207 8C15.7207 6.14348 14.9832 4.36301 13.6705 3.05025C12.3577 1.7375 10.5772 1 8.7207 1C6.86419 1 5.08371 1.7375 3.77096 3.05025C2.4582 4.36301 1.7207 6.14348 1.7207 8C1.7207 9.85652 2.4582 11.637 3.77096 12.9497C5.08371 14.2625 6.86419 15 8.7207 15ZM7.7537 8.783V9.126H9.0957V8.848C9.088 8.68833 9.1322 8.53045 9.2217 8.398C9.3097 8.284 9.5157 8.118 9.8397 7.898C10.3117 7.589 10.6327 7.311 10.8007 7.064C10.9697 6.816 11.0537 6.523 11.0537 6.183C11.0537 5.673 10.8477 5.265 10.4367 4.959C10.0287 4.653 9.4767 4.5 8.7817 4.5C7.94863 4.49763 7.12732 4.69661 6.3877 5.08L6.9387 6.095C7.5867 5.792 8.1637 5.64 8.6697 5.64C8.9597 5.64 9.1867 5.693 9.3487 5.798C9.42639 5.84596 9.48986 5.91379 9.53258 5.99448C9.5753 6.07516 9.59571 6.16579 9.5917 6.257C9.5917 6.437 9.5337 6.598 9.4187 6.744C9.3087 6.889 9.0747 7.077 8.7207 7.309C8.3527 7.557 8.1007 7.789 7.9607 8.009C7.81884 8.24178 7.74698 8.51048 7.7537 8.783ZM7.8197 10.123C7.6677 10.256 7.5917 10.453 7.5917 10.712C7.5917 10.962 7.6697 11.157 7.8247 11.296C7.9797 11.432 8.1997 11.5 8.4827 11.5C8.7597 11.5 8.9757 11.43 9.1307 11.291C9.2857 11.149 9.3637 10.956 9.3637 10.711C9.3637 10.459 9.2857 10.264 9.1307 10.128C8.9787 9.992 8.7627 9.924 8.4827 9.924C8.1927 9.924 7.9717 9.989 7.8197 10.123Z" fill="#7D8799" />
                                        </svg>
                                    </div>
                                    <span>6149f7d6e8944d0001cae98b</span>
                                </div>
                                <div className='display-4'>
                                    <Button className="csvDownloadBtn copy"><ContentCopyIcon /></Button>
                                    <Button className="csvDownloadBtn"><VerticalAlignBottomIcon />Download CSV</Button>
                                </div>
                            </div>

                            <div className="margin-top">
                                <div className='display-2 bor'>
                                    <div className="display-1 " style={{ flexWrap: 'wrap' }}>
                                        <div className="display-1">
                                            <div className="prd">Period:</div>
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth className='select-dash'>
                                                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={age}
                                                        // label="Age"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value={10}>from Aug 5, 2024 to Aug 6, 2024</MenuItem>
                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                        <div className="display-1">
                                            <div className="prd">Show:</div>
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth className='select-dash'>
                                                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={age}
                                                        // label="Age"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value={10}>Last 24 hours</MenuItem>
                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>
                                    <div><Button className='display-4 clear-btn'><HighlightOffIcon />Clear filters</Button></div>
                                </div>

                            </div>

                            <div className="margin-top-bor">
                                <div className='emptyChartOuter mt-0 p-0'>
                                    {/* <div className='inner-thead-th'>Checked Applicants (over time)</div> */}
                                    <div className='emptyChartDiv'>
                                        {/* <StatsChart /> */}
                                        <div className='nodata'>
                                            <div>No results found</div>
                                            <span>We couldn't find what you're looking for. Try a different search term</span>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="margin-top">
                            <div className="display-2">
                                <div className="margin-top analytic-head">
                                    <div className="getstar display-1 analytic-head-theme">
                                        Most common resubmissions reasons per country
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.7207 15C10.5772 15 12.3577 14.2625 13.6705 12.9497C14.9832 11.637 15.7207 9.85652 15.7207 8C15.7207 6.14348 14.9832 4.36301 13.6705 3.05025C12.3577 1.7375 10.5772 1 8.7207 1C6.86419 1 5.08371 1.7375 3.77096 3.05025C2.4582 4.36301 1.7207 6.14348 1.7207 8C1.7207 9.85652 2.4582 11.637 3.77096 12.9497C5.08371 14.2625 6.86419 15 8.7207 15ZM7.7537 8.783V9.126H9.0957V8.848C9.088 8.68833 9.1322 8.53045 9.2217 8.398C9.3097 8.284 9.5157 8.118 9.8397 7.898C10.3117 7.589 10.6327 7.311 10.8007 7.064C10.9697 6.816 11.0537 6.523 11.0537 6.183C11.0537 5.673 10.8477 5.265 10.4367 4.959C10.0287 4.653 9.4767 4.5 8.7817 4.5C7.94863 4.49763 7.12732 4.69661 6.3877 5.08L6.9387 6.095C7.5867 5.792 8.1637 5.64 8.6697 5.64C8.9597 5.64 9.1867 5.693 9.3487 5.798C9.42639 5.84596 9.48986 5.91379 9.53258 5.99448C9.5753 6.07516 9.59571 6.16579 9.5917 6.257C9.5917 6.437 9.5337 6.598 9.4187 6.744C9.3087 6.889 9.0747 7.077 8.7207 7.309C8.3527 7.557 8.1007 7.789 7.9607 8.009C7.81884 8.24178 7.74698 8.51048 7.7537 8.783ZM7.8197 10.123C7.6677 10.256 7.5917 10.453 7.5917 10.712C7.5917 10.962 7.6697 11.157 7.8247 11.296C7.9797 11.432 8.1997 11.5 8.4827 11.5C8.7597 11.5 8.9757 11.43 9.1307 11.291C9.2857 11.149 9.3637 10.956 9.3637 10.711C9.3637 10.459 9.2857 10.264 9.1307 10.128C8.9787 9.992 8.7627 9.924 8.4827 9.924C8.1927 9.924 7.9717 9.989 7.8197 10.123Z" fill="#7D8799" />
                                        </svg>
                                    </div>
                                    <span>6149f86c4a1c7c0001bdba47</span>
                                </div>
                                <div className='display-4'>
                                    <Button className="csvDownloadBtn copy"><ContentCopyIcon /></Button>
                                    <Button className="csvDownloadBtn"><VerticalAlignBottomIcon />Download CSV</Button>
                                </div>
                            </div>

                            <div className="margin-top">
                                <div className='display-2 bor'>
                                    <div className="display-1 " style={{ flexWrap: 'wrap' }}>
                                        <div className="display-1">
                                            <div className="prd">Period:</div>
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth className='select-dash'>
                                                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={age}
                                                        // label="Age"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value={10}>from Aug 5, 2024 to Aug 6, 2024</MenuItem>
                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                        <div className="display-1">
                                            <div className="prd">Show:</div>
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth className='select-dash'>
                                                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={age}
                                                        // label="Age"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value={10}>Last 24 hours</MenuItem>
                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>
                                    <div><Button className='display-4 clear-btn'><HighlightOffIcon />Clear filters</Button></div>
                                </div>

                            </div>

                            <div className="margin-top-bor">
                                <div className='emptyChartOuter mt-0 p-0'>
                                    {/* <div className='inner-thead-th'>Checked Applicants (over time)</div> */}
                                    <div className='emptyChartDiv'>
                                        <div className='nodata'>
                                            <div>No results found</div>
                                            <span>We couldn't find what you're looking for. Try a different search term</span>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="margin-top">
                            <div className="display-2">
                                <div className="margin-top analytic-head">
                                    <div className="getstar display-1 analytic-head-theme">
                                        WebSDK Geo Usage
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.7207 15C10.5772 15 12.3577 14.2625 13.6705 12.9497C14.9832 11.637 15.7207 9.85652 15.7207 8C15.7207 6.14348 14.9832 4.36301 13.6705 3.05025C12.3577 1.7375 10.5772 1 8.7207 1C6.86419 1 5.08371 1.7375 3.77096 3.05025C2.4582 4.36301 1.7207 6.14348 1.7207 8C1.7207 9.85652 2.4582 11.637 3.77096 12.9497C5.08371 14.2625 6.86419 15 8.7207 15ZM7.7537 8.783V9.126H9.0957V8.848C9.088 8.68833 9.1322 8.53045 9.2217 8.398C9.3097 8.284 9.5157 8.118 9.8397 7.898C10.3117 7.589 10.6327 7.311 10.8007 7.064C10.9697 6.816 11.0537 6.523 11.0537 6.183C11.0537 5.673 10.8477 5.265 10.4367 4.959C10.0287 4.653 9.4767 4.5 8.7817 4.5C7.94863 4.49763 7.12732 4.69661 6.3877 5.08L6.9387 6.095C7.5867 5.792 8.1637 5.64 8.6697 5.64C8.9597 5.64 9.1867 5.693 9.3487 5.798C9.42639 5.84596 9.48986 5.91379 9.53258 5.99448C9.5753 6.07516 9.59571 6.16579 9.5917 6.257C9.5917 6.437 9.5337 6.598 9.4187 6.744C9.3087 6.889 9.0747 7.077 8.7207 7.309C8.3527 7.557 8.1007 7.789 7.9607 8.009C7.81884 8.24178 7.74698 8.51048 7.7537 8.783ZM7.8197 10.123C7.6677 10.256 7.5917 10.453 7.5917 10.712C7.5917 10.962 7.6697 11.157 7.8247 11.296C7.9797 11.432 8.1997 11.5 8.4827 11.5C8.7597 11.5 8.9757 11.43 9.1307 11.291C9.2857 11.149 9.3637 10.956 9.3637 10.711C9.3637 10.459 9.2857 10.264 9.1307 10.128C8.9787 9.992 8.7627 9.924 8.4827 9.924C8.1927 9.924 7.9717 9.989 7.8197 10.123Z" fill="#7D8799" />
                                        </svg>
                                    </div>
                                    <span>5e6b608d04f940658dec8436</span>
                                </div>
                                <div className='display-4'>
                                    <Button className="csvDownloadBtn copy"><ContentCopyIcon /></Button>
                                    {/* <Button className="csvDownloadBtn"><VerticalAlignBottomIcon />Download CSV</Button> */}
                                </div>
                            </div>

                            <div className="margin-top">
                                <div className='display-2 bor'>
                                    <div className="display-1 " style={{ flexWrap: 'wrap' }}>
                                        <div className="display-1">
                                            <div className="prd">Period:</div>
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth className='select-dash'>
                                                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={age}
                                                        // label="Age"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value={10}>from Aug 5, 2024 to Aug 6, 2024</MenuItem>
                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                        <div className="display-1">
                                            <div className="prd">Show:</div>
                                            <Box sx={{ minWidth: 120 }}>
                                                <FormControl fullWidth className='select-dash'>
                                                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={age}
                                                        // label="Age"
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value={10}>Last 24 hours</MenuItem>
                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                    </div>
                                    {/* <div><Button className='display-4 clear-btn'><HighlightOffIcon />Clear filters</Button></div> */}
                                </div>

                            </div>

                            <div className="margin-top-bor">
                                <div className='emptyChartOuter mt-0 p-0'>
                                    {/* <div className='inner-thead-th'>Checked Applicants (over time)</div> */}
                                    <div className='emptyChartDiv margin-top'>
                                        <StatsGoogleMap />
                                    </div>

                                </div>
                            </div>
                        </div>


                    </CustomTabPanel>
                </Box>
            </Box>
        </div>
    )
}

export default Statistics
