import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Logo from '../../../Images/logo.png';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Apzykyc from '../../../Images/individual/apzkyc-logo.png';
import LoginBanner from '../../../Images/individual/login-img.png';
import RegSta from '../../../Images/regsta.png';
import './Login.css'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Register = () => {

    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <div className='individual-signin' style={{ height: '100vh', overflow: 'hidden' }}>
            <Box>
                <Grid container spacing={4}>
                    {mdScreen && <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <div className="login-left-part">
                            <div className="logo-part">
                                <Link to='/'>
                                    <img src={Apzykyc} alt="Apzykyc" />
                                </Link>

                            </div>
                            <div className="login-banner" style={{ display: 'flex', alignItems: 'flex-end', height: '100%' }}>
                                <img src={RegSta} alt="LoginBanner" />
                            </div>
                        </div>
                    </Grid>}
                    <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                        <div className="register-acctn trouble-shoot">

                            <p>Having trouble? <Link to=''>Get help </Link></p>
                        </div>
                        <Grid container spacing={4}>

                        </Grid>
                        <div className='login-grid'>
                            <h1 className='cracct'>Create an account</h1>
                            <p className='cracct-desc'>Sign up with your email address so that your information is not lost and safe
                                with us.</p>

                            <div className="individual-form">
                                <div className="display-2" style={{ flexFlow: 'wrap' }}>
                                    <div className='email-filed phr'>
                                        <div className='display-1 fllname'>Full Name</div>
                                        <TextField placeholder='Enter full name' style={{ width: !mdScreen ? '100%' : '280px' }} />
                                    </div>
                                    <div className='email-filed phr'>
                                        <div className='display-1 fllname'>Nick Name</div>
                                        <TextField placeholder='Enter nick name' style={{ width: !mdScreen ? '100%' : '280px' }} />
                                    </div>
                                </div>

                                <div className='email-filed phr'>
                                    <div className='display-1 fllname'>Email<span>(Default)</span></div>
                                    <TextField placeholder='Enter email address' />
                                </div>
                                <div className='email-filed phr'>
                                    <div className='display-1 fllname'>Phone <span>(Default)</span></div>
                                    <TextField placeholder='Enter phone number' />

                                </div>
                                <div className='individual-submit save-btn'>
                                    <Link to='/individual/createpswd'>SAVE & CONTINUE</Link>
                                </div>

                                <div className="register-acctn sign-acctn margin-top">
                                    <p>Already have an account?<Link to='/individual/signin'> Log in</Link></p>
                                </div>
                            </div>

                        </div>


                    </Grid>
                </Grid>
            </Box>
        </div >
    )
}

export default Register
