import { Grid, Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import Apzykyc from '../../../Images/individual/apzkyc-logo.png';
import LoginBanner from '../../../Images/individual/login-img.png';
import crpswd from '../../../Images/crpswd.png';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';

function CreatePswd() {

    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.up('md'));
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const navigate = useNavigate()

    return (
        <div className='create-pswd' style={{ height: '100vh', overflow: 'hidden' }}>
            <Grid container spacing={4} sx={{ justifyContent: "center" }}>
                <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                    <div className="login-left-part">
                        <div className="logo-part text-center">
                            <Link to='/'>
                                <img src={Apzykyc} alt="Apzykyc" />
                            </Link>

                        </div>
                        <div className="matgin-top cr-whole">
                            <h2 className='ver-hd'>Enter your Email</h2>
                            {/* <p className='ver-p'>Please check your inbox for a verification link. If you didn't receive it, click the resend link below.</p> */}

                            <div className='crpswd margin-t-10px'>
                                <label className='display-1 crpswd-txt'>Email</label>
                                <TextField id="outlined-basic" placeholder='email address' variant="outlined" />
                                {/* <div className='forget-link '><Link to=''>Forget Password?</Link></div> */}
                            </div>

                            <div className="vrfbtn">
                                <Button onClick={(() => { navigate('/individual/verify') })}>Send OTP </Button>
                            </div>
                        </div>
                        {mdScreen && <div className="login-banner xl text-center">
                            <img src={crpswd} alt="LoginBanner" />
                        </div>}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default CreatePswd
