
import './App.css';
import './Media.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Landing from './Business/Components/Landing/Landing';
import SignUp from './Business/Components/Authentication/SignUp';
import Login from './Business/Components/Authentication/Login';
import ClientLists from './Business/Components/ClientLists/ClientLists';
import ProfileSetting from './Business/Components/ProfileSetting/ProfileSetting';
import CreatePassword from './Business/Components/Authentication/CreatePassword';
import Sidebar from './Business/Components/SideBar/Sidebar';
import Dashboard from './Business/Components/Dashboard/Dashboard';
import Pricing from './Business/Components/Pricing/Pricing';
import Applicant from './Business/Components/Applicant/Applicant'
import Applicant3 from './Business/Components/Applicant/Applicant3';
import ReApplicants from './Business/Components/ReApplicants/ReApplicants';
import Settings from './Business/Components/Settings/Settings';
import NewApplicant from './Business/Components/Applicant/NewApplicant';
import Statistics from './Business/Components/Statistics/Statistics';
import Activate from './Business/Components/Authentication/Activate';
import SignIn from './Individual/Components/Authentication/Login';
import Register from './Individual/Components/Authentication/Register';
import Verify from './Individual/Components/Authentication/Verify';
import CreatePswd from './Individual/Components/Authentication/CreatePswd';
import Header from './Individual/Components/Header/Header';
import Document from './Individual/Components/Documents/Document';
import Kyc from './Individual/Components/Kyc/Kyc';
import Comingsoon from './Comingsoon';
import Comingsoon2 from './Comingsoon2';
import VerifyEmail from '././Individual/Components/Authentication/VerifyEmail'
import EditProfileSetting from './Business/Components/ProfileSetting/EditProfileSetting';
import Overview from './Individual/Components/Overview/Overview';
import Support  from './Individual/Components/Support/Support';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/create-password' element={<CreatePassword />} />
          <Route path='/login' element={<Login />} />
          <Route path='/activate' element={<Activate />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/sidebar' element={<Sidebar />} />
          <Route path='/clientList' element={<ClientLists />} />
          <Route path='/profileSetting' element={<ProfileSetting />} />
          <Route path='/applicant' element={<Applicant />} />
          <Route path='/applicant3' element={<Applicant3 />} />
          <Route path='/reApplicants' element={<ReApplicants />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/newapplicant' element={<NewApplicant />} />
          <Route path='/statistics' element={<Statistics />} />
          <Route path='/editProfile' element={<EditProfileSetting />} />


{/* // coming */}
          <Route path='/comingsoon' element={<Comingsoon />} />
          <Route path='/intgration' element={<Comingsoon />} />


{/* // Individual */}
          <Route path='/individual/signin' element={<SignIn />} />
          <Route path='/individual/register' element={<Register />} />
          <Route path='/individual/verifyemail' element={<VerifyEmail />} />
          <Route path='/individual/verify' element={<Verify />} />
          <Route path='/individual/createpswd' element={<CreatePswd />} />
          <Route path='/individual/header' element={<Header />} />
          <Route path='/individual/document' element={<Document />} />
          <Route path='/individual/kyc' element={<Kyc />} />
          <Route path='/individual/settings' element={<Comingsoon2 />} />
          <Route path='/individual/overview' element={<Overview />} />
          <Route path='/individual/support' element={<Support />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
