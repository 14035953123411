import React, { useState,useEffect, useRef } from 'react';
import {
  Box, TextField, Button, List, ListItem, ListItemText, Avatar, Typography, Paper, Divider, Grid, Stepper
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { deepPurple, deepOrange } from '@mui/material/colors';
import './Support.css'
import Header from '../Header/Header'

const Support    = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const handleSend = () => {
    if (input.trim() !== '') {
      const newMessage = {
        text: input,
        timestamp: new Date().toLocaleTimeString(),
        sender: 'You', // Placeholder sender
      };
      setMessages([...messages, newMessage]);
      setInput('');
    }
  };

  const [msg, setMsg] = useState([]);
  const chatEndRef = useRef(null);

  const addMessage = (message) => {
    setMsg((prevMessages) => [...prevMessages, message]);
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [msg]);

  
  return (
    <div className='chatBox'>
    <Header />
    <Grid container className='margin-top support_whole_grid' sx={{ height: '88%' }}>
        <Grid xs={12} sm={12} md={12} lg={3} xl={4} className='dcBk'>
                    <div className="support-left-main" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
                    
                        <div className="helpmain">
                            <div className="quest text-center">
                                <svg width="96" height="92" viewBox="0 0 96 92" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_f_33_4124)">
                                        <rect x="24" y="24" width="48" height="44" rx="22" fill="#141522" fill-opacity="0.4" />
                                    </g>
                                    <rect x="26" y="18" width="44" height="44" rx="22" fill="#141522" stroke="white" stroke-width="4" />
                                    <path d="M48.844 45.764V45.12C48.844 43.692 49.908 42.852 51.28 41.872C52.904 40.724 54.836 39.296 54.836 36.3C54.836 32.576 52.316 30.168 48.144 30.168C44.084 30.168 41.144 32.996 41.144 37H44.7C44.7 34.844 46.072 33.36 48.06 33.36C50.02 33.36 51.196 34.62 51.196 36.524C51.196 38.148 50.048 39.1 48.788 40.108C47.304 41.256 45.736 42.572 45.736 45.12V45.764H48.844ZM47.416 51.364C48.648 51.364 49.684 50.328 49.684 49.152C49.684 47.92 48.648 46.912 47.416 46.912C46.156 46.912 45.148 47.92 45.148 49.152C45.148 50.356 46.156 51.364 47.416 51.364Z" fill="white" />
                                    <defs>
                                        <filter id="filter0_f_33_4124" x="0" y="0" width="96" height="92" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                            <feGaussianBlur stdDeviation="12" result="effect1_foregroundBlur_33_4124" />
                                        </filter>
                                    </defs>
                                </svg>
                            </div>
                            <div className="hlp-txt">
                                Help Center
                            </div>
                            <div className="hlp-desc">
                                Having Trouble in Learning.
                                Please contact us for more questions.
                            </div>
                            <div className="go-help">
                                <Button>Go To Help Center</Button>
                            </div>
                        </div>
                    </div>
        </Grid>

        <Grid xs={12} sm={12} md={12} lg={9} xl={8}>
        <Box className='ChatBox_grid_box'>
        <Typography variant="h5" gutterBottom className='display-1'>
        Minerva Barnett
        <div><span>Friends</span></div>
        </Typography>
        <List sx={{ height: 400, overflowY: 'auto', mt: 2 }}>
            {messages.map((msg, index) => (
                
            <ListItem key={index} alignItems="flex-start">
                <Avatar sx={{ bgcolor: deepPurple[500], mr: 2 }}>
                {msg.sender[0]}
                </Avatar>
                <ListItemText className='sender_chat'
                primary={
                    <React.Fragment className="sender">
                    <Typography
                        component="span"
                        variant="body1"
                        color="text.primary"
                        sx={{ fontWeight: 'bold' }}
                        className="sender"
                    >
                        {msg.sender}
                    </Typography>
                    <Typography
                        component="span"
                        variant="body2"
                        color="text.secondary"
                        sx={{ ml: 2 }}
                        className='current_time'
                    >
                        {msg.timestamp}
                    </Typography>
                    </React.Fragment>
                }
                secondary={msg.text}
              
                />
            </ListItem>
            
            ))}
            <li alignItems="flex-start"  className='userTwoChat'>
                    <Avatar sx={{ bgcolor: deepPurple[500], mr: 2 }}>
                    </Avatar>
                    <ListItemText
                    primary={
                        <React.Fragment>
                        <Typography
                            component="span"
                            variant="body1"
                            color="text.primary"
                            sx={{ fontWeight: 'bold' }}
                            className="sender"
                        >
                        Friend
                        </Typography>
                        <Typography
                            component="span"
                            variant="body2"
                            color="text.secondary"
                            sx={{ ml: 2 }}
                            className='current_time'
                        >
                       3:31:18 PM
                        </Typography>
                        </React.Fragment>
                    }
                    // secondary={msg.text}
                    secondary={
                            <Typography className='receiver_chat'>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default.Contrary to popular belief, Lorem Ipsum is not simply random text is the model text for your company.
                                </Typography>
                    }
                    />
                </li>
        </List>


        <Divider sx={{ mt: 2 }} />
        <Box display="flex" mt={2} className="chat_send_div">
            <TextField
            variant="outlined"
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Write massage"
            className='chat_textField'
            />
            <Button
            variant="contained"
            onClick={handleSend}
            endIcon={<SendIcon />}
            sx={{ ml: 2 }}
            className='send_btn'
            >
            Send
            </Button>
        </Box>
        <div ref={chatEndRef} />
        </Box>
        </Grid>

    </Grid>

    </div>
   
  );
};

export default Support;
