import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Logo from '../../../Images/logo.png';
import { Link } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Apzykyc from '../../../Images/individual/apzkyc-logo.png';
import LoginBanner from '../../../Images/individual/login-img.png';
import './Login.css'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Login = () => {

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <div className='individual-signin' style={{ height: '100vh', overflow: 'hidden' }}>
            <Box>
                <Grid container spacing={0}>
                    {mdScreen && <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <div className="login-left-part">
                            <div className="logo-part">
                                <Link to='/'>
                                    <img src={Apzykyc} alt="Apzykyc" />
                                </Link>

                            </div>
                            <div className="login-banner" style={{ display: 'flex', alignItems: 'flex-end', height: '100%' }}>
                                <img src={LoginBanner} alt="LoginBanner" />
                            </div>
                        </div>
                    </Grid>}
                    <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
                        <div className="register-acctn trouble-shoot">

                            <p>Having trouble? <Link to=''>Get help </Link></p>
                        </div>
                        <div className='login-grid'>
                            <h1 className='cracct'>Login <span>✌</span></h1>
                            <p className='cracct-desc'>Thank you for get back to APZKYC.</p>

                            <div className="individual-form">
                                <div className='email-filed phr'>
                                    <label className='display-1 fllname'>Email</label>
                                    <TextField placeholder='Enter email address' />
                                </div>
                                <div className='email-filed phr'>
                                    <label className='display-1 fllname'>Password</label>
                                    <FormControl variant="outlined">
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Password'
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    <div className='forget-link fllname'><Link to='/individual/verifyemail'>Forgot Password?</Link></div>
                                </div>
                                <div className='individual-submit margin-t-30px'>
                                    <Link to='/individual/kyc'>Submit</Link>
                                </div>

                                <div className="register-acctn">
                                    <p>Don't have an account? <Link to='/individual/register'>Sign up</Link></p>
                                </div>
                            </div>

                        </div>


                    </Grid>
                </Grid>
            </Box>
        </div >
    )
}

export default Login
