import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Button, Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import "./ClientLists.css";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Sidebar from "../SideBar/Sidebar";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

// selector one data

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];




export default function ClientLists() {
  // create popup open and close

  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.up('md'));

  const MobileScreen = useMediaQuery(theme.breakpoints.up('sm'));



  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openimport, setOpenImport] = React.useState(false);

  const handleClickImport = () => {
    setOpenImport(true);
  };
  const handleCloseImport = () => {
    setOpenImport(false);
  };

  // pagination

  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // popup selector 1

  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  // selector 2 data

  const [age, setAge] = React.useState('');

  const handleSelectChange = (event) => {
    setAge(event.target.value);
  };

  //  select file

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // const handleUpload = () => {
  //   if (selectedFile) {
  //     console.log('File Selected:', selectedFile.name);
  //     // You can add your upload logic here
  //   }
  // };

  // table data
  function createdData(
    title,
    name,
    description,
    entry,
    type,
    date,
    time,
    gmt
  ) {
    return { title, name, description, entry, type, date, time, gmt };
  }

  const List = [
    createdData('sdad', 'sdad', 'asdasd', 'Applicant ids', 'Blocklist', 'Aug 6, 2024,', '5:12 PM', '(GMT+5:30)'),
    createdData('asdsdas', 'asdsdas', 'asdasd', 'Key', 'Custom', 'Aug 6, 2024,', '5:12 PM', '(GMT+5:30)'),
    createdData('sds', 'sds', 'wwewe', 'Key', 'Custom', 'Aug 6, 2024,', '5:12 PM', '(GMT+5:30)'),
    createdData('Gr', 'gr', 'sddf', 'Key', 'Custom', 'Aug 6, 2024,', '5:12 PM', '(GMT+5:30)'),
  ];

  return (
    <div>
      <Box sx={{ display: mdScreen ? 'flex' : 'block' }}>
        <Sidebar />
        <Box component="main" className="clientList-body" sx={{ flexGrow: 1, p: 3, marginTop: '80px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className='display-4'>
                <div>
                  <Button className="add-list-btn cmn-btn" onClick={handleClickOpen}><AddIcon />Create list</Button>
                  <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open} className='createList-popup'
                  >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                      Create list
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        // color: (theme) => theme.palette.grey[500],
                        color: "#212736 !important",
                      }}
                    >
                      <CloseIcon className='close-svg' />
                    </IconButton>
                    <DialogContent>
                      <div className='email-field'>
                        <TextField fullWidth id="fullWidth" placeholder='Title' />
                      </div>
                      <div className='email-field'>
                        <TextField fullWidth id="fullWidth" placeholder='Description' />
                      </div>

                      <div className="entry">
                        <label className='display-1'>Entry type</label>

                        <FormControl class="formControl-flex">
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group">
                            <FormControlLabel value="key" control={<Radio />} label="Key" checked />
                            <FormControlLabel value="ids" control={<Radio />} label="Applicant ids" />
                            <FormControlLabel value="email" control={<Radio />} label="Emails" />
                            <FormControlLabel value="device" control={<Radio />} label="Device Fingerprints" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </DialogContent>

                    <DialogActions>
                      {/* autoFocus onClick={handleClose} */}
                      <Button className='add-list-btn action-btn ' >
                        Create
                      </Button>
                    </DialogActions>
                  </BootstrapDialog>
                </div>

                <div>
                  <Button className="import-btn cmn-btn" onClick={handleClickImport}><FileDownloadOutlinedIcon />Import list</Button>
                  <BootstrapDialog
                    onClose={handleCloseImport}
                    aria-labelledby="customized-dialog-title"
                    open={openimport} className='createList-popup'
                  >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                      Import list Items
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={handleCloseImport}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        // color: (theme) => theme.palette.grey[500],
                        color: "#212736 !important",
                      }}
                    >
                      <CloseIcon class="close-svg" />
                    </IconButton>
                    <DialogContent>
                      <div>
                        <FormControl sx={{ m: 0, width: '100%' }}>
                          {/* <InputLabel id="demo-multiple-checkbox-label">Select List</InputLabel> */}
                          <Select
                            className="select-item-list email-field"
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            placeholder='Select List'
                            value={personName}
                            onChange={handleChange}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => (
                              <MenuItem key={name} value={name}>
                                <Checkbox checked={personName.indexOf(name) > -1} />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div class="import-popup">
                        <InputLabel id="demo-simple-select-label seperate">Seperator</InputLabel>
                        <FormControl sx={{ m: 0, width: 160 }}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            placeholder='Age'
                            className='select-item-list email-field'
                            value={age}
                            onChange={handleSelectChange}
                          >
                            <MenuItem value={','}>comma</MenuItem>
                            <MenuItem value={'.'}>dot</MenuItem>
                            <MenuItem value={';'}>semicolon</MenuItem>
                            <MenuItem value={'@'}>@</MenuItem>
                            <MenuItem value={'#'}>Hash</MenuItem>
                            <MenuItem value={'&'}>
                              ampersand</MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      <Box className='email-field' sx={{ display: 'flex', flexDirection: 'column', }}>
                        <input
                          accept="image/*"
                          style={{ display: 'none' }}
                          id="raised-button-file"
                          type="file"
                          onChange={handleFileChange}
                        />
                        <label htmlFor="raised-button-file" className='upload-label'>
                          <Button variant="contained" component="span" className='upload-btn'>
                            <FileUploadOutlinedIcon />
                            Select File
                          </Button>
                        </label>
                        {selectedFile && (
                          <Typography variant="body1" sx={{ mt: 2 }}>
                            Selected File: {selectedFile.name}
                          </Typography>
                        )}
                        <Button
                          variant="contained"
                          color="primary"
                          // onClick={handleUpload}
                          sx={{ mt: 2, display: 'none' }}
                          disabled={!selectedFile}
                        >
                          Upload
                        </Button>
                      </Box>
                      <Typography variant="h6" className='import-info' sx={{ mt: 2 }}>
                        Load your CSV file. Use line breaks to separate the lines. The maximum
                        number of rows is 10,000. If there is more than one separator, the remaining
                        columns will be ignored. Click Import to preview the uploaded data.
                      </Typography>
                    </DialogContent>

                    <DialogActions>
                      {/* autoFocus onClick={handleClose} */}
                      <Button className='add-list-btn action-btn ' >
                        Import
                      </Button>
                    </DialogActions>
                  </BootstrapDialog>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className='item-list-table'>
                <TableContainer >
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Title</TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="left">Entry type</TableCell>
                        <TableCell align="left">Type</TableCell>
                        <TableCell align="center">Created at</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {List?.map((List, index) => (
                        <TableRow key={index}>
                          <TableCell>{List.title}</TableCell>
                          <TableCell>{List.name}</TableCell>
                          <TableCell>{List.description}</TableCell>
                          <TableCell>{List.entry}</TableCell>
                          <TableCell>{List.type}</TableCell>
                          <TableCell>
                            <div>
                              {List.date}
                              {List.time}
                              <br></br>
                              {List.gmt}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className='action-btn-flex display-4'>
                              <Button><ModeOutlinedIcon className='action-btn-bdr' /></Button>
                              <Button><FileUploadOutlinedIcon className='action-btn-bdr' /></Button>
                              <Button><DeleteOutlineTwoToneIcon className='action-btn-bdr' /></Button>
                            </div>

                          </TableCell>

                        </TableRow>
                      ))
                      }

                    </TableBody>


                  </Table>
                </TableContainer>
                <>
                  <div className='add-false-content'>
                    There are no items yet
                  </div>
                </>
              </div>

              <TablePagination
                component="div"
                count={100}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>

          </Grid>
        </Box>
      </Box>
    </div>
  )
}
