import React, { useState } from 'react'
import Header from '../Header/Header'
import { Grid } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import './Document.css'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import nodata from '../../../Images/nodata.gif'

function Document() {
    const [tab, setTab] = useState(1)

    const handleTab = (val) => {
        setTab(val)
    }
    const pathColor = (
        tab === 1 ? '#fff' : '#8E92BC'
    )

    const pathColor2 = (
        tab === 2 ? '#fff' : '#8E92BC'
    )


    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <div className='document'>
            <Header />
            <Grid container className='margin-top'>

                <Grid xs={12} sm={12} md={3} lg={3} xl={4} className='dcBk'>
                    <div className="">
                        <div className={tab === 1 ? "dc-tab display-1 cursor" : "dc-tab2 display-1 cursor"} onClick={(() => { handleTab(1) })}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M21.0799 8.58003V15.42C21.0799 16.54 20.4799 17.58 19.5099 18.15L13.5699 21.58C12.5999 22.14 11.3999 22.14 10.4199 21.58L4.47991 18.15C3.50991 17.59 2.90991 16.55 2.90991 15.42V8.58003C2.90991 7.46003 3.50991 6.41999 4.47991 5.84999L10.4199 2.42C11.3899 1.86 12.5899 1.86 13.5699 2.42L19.5099 5.84999C20.4799 6.41999 21.0799 7.45003 21.0799 8.58003Z" stroke={pathColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M11.9999 10.9998C13.2867 10.9998 14.3299 9.95662 14.3299 8.6698C14.3299 7.38298 13.2867 6.33984 11.9999 6.33984C10.7131 6.33984 9.66992 7.38298 9.66992 8.6698C9.66992 9.95662 10.7131 10.9998 11.9999 10.9998Z" stroke={pathColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16 16.6603C16 14.8603 14.21 13.4004 12 13.4004C9.79 13.4004 8 14.8603 8 16.6603" stroke={pathColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <div className={tab === 1 ? "dc-tabtext" : "dc-tabtext2"}>Mentors</div>
                        </div>
                        <div className={tab === 2 ? "dc-tab display-1 cursor" : "dc-tab2 display-1 cursor"} onClick={(() => { handleTab(2) })}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M22 16.7397V4.6697C22 3.4697 21.02 2.5797 19.83 2.6797H19.77C17.67 2.8597 14.48 3.9297 12.7 5.0497L12.53 5.1597C12.24 5.3397 11.76 5.3397 11.47 5.1597L11.22 5.0097C9.44 3.8997 6.26 2.8397 4.16 2.6697C2.97 2.5697 2 3.4697 2 4.6597V16.7397C2 17.6997 2.78 18.5997 3.74 18.7197L4.03 18.7597C6.2 19.0497 9.55 20.1497 11.47 21.1997L11.51 21.2197C11.78 21.3697 12.21 21.3697 12.47 21.2197C14.39 20.1597 17.75 19.0497 19.93 18.7597L20.26 18.7197C21.22 18.5997 22 17.6997 22 16.7397Z" stroke={pathColor2} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12 5.49023V20.4902" stroke={pathColor2} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.75 8.49023H5.5" stroke={pathColor2} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.5 11.4902H5.5" stroke={pathColor2} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <div className={tab === 2 ? "dc-tabtext" : "dc-tabtext2"}>API Keys</div>
                        </div>
                    </div>
                </Grid>

                <Grid xs={12} sm={12} md={9} lg={9} xl={8}>
                    <div className="register-acctn trouble-shoot">
                        <p>Having trouble? <Link to=''>Get help </Link></p>
                    </div>

                    {tab === 1 && <div className="margin-top">
                        {['', '', ''].map((row, i) => {
                            return (
                                <div className="doc-mn">
                                    <div className="display-2" style={{ flexDirection: !matches && 'column' }}>
                                        <div className="jip-nm">
                                            CmiUf4W4CPr3l2eIWHJBHR .jpg
                                        </div>
                                        <div className="dat">
                                            12 Jan 2022 03:45 PM
                                        </div>
                                    </div>
                                    <div className="display-1 margin-t-10px" style={{ flexDirection: !matches && 'column' }}>
                                        <div className="doc-im">
                                            {/* <img src={docim} alt="docim" /> */}
                                        </div>
                                        <div className="">
                                            <div className="doc-name">
                                                Document name
                                                <div className="docty">
                                                    Adhaar card
                                                </div>
                                            </div>
                                            <div className="doc-name">
                                                Country issued by
                                                <div className="docty">
                                                    India
                                                </div>
                                            </div>
                                            <div className="doc-name">
                                                Status
                                                <div className="verify">
                                                    Verified
                                                </div>
                                            </div>
                                            <div className="req-doc cursor">
                                                + Request update document
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )
                        })

                        }
                    </div>}
                    {tab === 2 && <div className="text-center">
                        <img src={nodata} alt="nodata" />
                    </div>}


                </Grid>

            </Grid>
        </div>
    )
}

export default Document
