import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom'
import './Login.css';
import { TextField, Button, Typography, Container, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Logo from '../../../Images/logo.png';

const Activate = () => {

    const [otp, setOtp] = useState(Array(6).fill(''));
    const inputRefs = useRef([]);

    const handleChange = (index, event) => {
        const value = event.target.value;

        if (/^[0-9]$/.test(value) || value === '') {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (value && index < 5) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const otpString = otp.join('');

        if (otpString.length !== 6) {
            alert('OTP must be 6 digits.');
            return;
        }

    };




    return (
        <div className='login-full activate'>
            <div className='contain-width'>
                <div className='login-bg'>

                    <div className='back-btn'>
                        <Link to="/signup"> <ArrowBackIosIcon /> Back</Link>
                    </div>

                    <div className='logo-bar'>
                        <Link to="/"><img src={Logo} />
                        </Link>

                    </div>
                    <h1>Activate your account</h1>
                    <p>We've sent you a confirmation code via email <Link style={{ margin: '0 5px' }} to="">  no-reply@apzex.com.</Link>
                        Please enter it below</p>
                    <div className='textfield'>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} justifyContent="center">
                                {otp.map((digit, index) => (
                                    <Grid item key={index}>
                                        <TextField
                                            inputRef={(ref) => (inputRefs.current[index] = ref)}
                                            value={digit}
                                            onChange={(event) => handleChange(index, event)}
                                            onKeyDown={(event) => handleKeyDown(index, event)}
                                            variant="outlined"
                                            margin="normal"
                                            type="text"
                                            inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </form>

                        <p>Didn't receive the code? <Link to="">Resend code</Link></p>


                        <div className='submit-button cursor'>
                            <Link to="/create-password">Continue</Link>
                        </div>



                    </div>
                </div>
            </div>

        </div>
    )
};

export default Activate;
