import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Button, Box, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import "./ProfileSetting.css";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import Sidebar from "../SideBar/Sidebar";
import countryFlag from "../../../Images/country.svg";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Link } from 'react-router-dom';
import CreateIcon from '@mui/icons-material/Create';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  '@media (max-width: 991.98px)': {
    width: '70%',
  },
  '@media (max-width: 575.98px)': {
    width: '85%',
  },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};


// selector one data

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

// tab
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



export default function ProfileSetting() {
  // responsive
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.up('md'));
  const MobileScreen = useMediaQuery(theme.breakpoints.up('sm'));


  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);


  // pagination

  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // table data
  function createdData(
    date,
    time,
    gmt,
    exDate,
    exTime,
    exGmt,
    source,
    ip,
    platform,
    chrome,
    dtype,
    dmarker,
    dmodel,
    countryFlag,
    country,
    city,

  ) {
    return { date, time, gmt, exDate, exTime, exGmt, source, ip, platform, chrome, dtype, dmarker, dmodel, countryFlag, country, city };
  }

  const List = [
    createdData('Aug 6, 2024,', '4:19 PM', '(GMT+5:30)', 'Aug 7, 2024', '9:32 AM', '(GMT+5:30)', 'dashboard', '49.37.202.209', 'Win10', 'Chrome', 'Type: Desktop', 'Maker: Unknown', 'Model: Windows Desktop', countryFlag, 'India', 'Chennai'),
    createdData('Aug 6, 2024,', '4:19 PM', '(GMT+5:30)', 'Aug 7, 2024', '9:32 AM', '(GMT+5:30)', 'dashboard', '49.37.202.209', 'Win10', 'Chrome', 'Type: Desktop', 'Maker: Unknown', 'Model: Windows Desktop', countryFlag, 'India', 'Chennai'),
    createdData('Aug 6, 2024,', '4:19 PM', '(GMT+5:30)', 'Aug 7, 2024', '9:32 AM', '(GMT+5:30)', 'dashboard', '49.37.202.209', 'Win10', 'Chrome', 'Type: Desktop', 'Maker: Unknown', 'Model: Windows Desktop', countryFlag, 'India', 'Chennai'),
    createdData('Aug 6, 2024,', '4:19 PM', '(GMT+5:30)', 'Aug 7, 2024', '9:32 AM', '(GMT+5:30)', 'dashboard', '49.37.202.209', 'Win10', 'Chrome', 'Type: Desktop', 'Maker: Unknown', 'Model: Windows Desktop', countryFlag, 'India', 'Chennai'),
  ];

  // tab

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // selector 2 data

  const [Language, setLanguage] = React.useState('');

  const handleSelectLanguage = (event) => {
    setLanguage(event.target.value);
  };

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [isDisabled, setIsDisabled] = useState(false);

  const handleDisableButton = () =>{
    setIsDisabled(!isDisabled);
  }
 
  return (
    <div>
      <Box sx={{ display: mdScreen ? 'flex' : 'block' }}>
        <Sidebar />
        <Box component="main" className="clientList-body" sx={{ flexGrow: 1, p: 3, marginTop: '80px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className='profile-tab-outer-div'>
                <div>
                  <div class="import-popup profile-set-head display-2 ">
                    <h1>Profile settings</h1>
                    <div className='profile-lang'>
                      <InputLabel id="demo-simple-select-label seperate" className='display-4'><div><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7562 7.78337C12.7562 8.49156 12.6167 9.19281 12.3457 9.84709C12.0747 10.5014 11.6775 11.0959 11.1767 11.5966C10.6759 12.0974 10.0814 12.4946 9.42717 12.7656C8.77289 13.0366 8.07164 13.1761 7.36345 13.1761C6.65527 13.1761 5.95402 13.0366 5.29974 12.7656C4.64546 12.4946 4.05097 12.0974 3.5502 11.5966C3.04944 11.0959 2.65221 10.5014 2.3812 9.84709C2.11019 9.19281 1.9707 8.49156 1.9707 7.78337C1.9707 6.35313 2.53887 4.98146 3.5502 3.97012C4.56154 2.95879 5.93321 2.39062 7.36345 2.39062C8.7937 2.39062 10.1654 2.95879 11.1767 3.97012C12.188 4.98146 12.7562 6.35313 12.7562 7.78337ZM7.36345 12.0976C8.36658 11.0481 8.97129 9.68098 9.07295 8.23277H5.65395C5.75569 9.68096 6.36039 11.0481 7.36345 12.0976ZM8.5202 12.1272C9.40286 11.8916 10.1928 11.3927 10.785 10.697C11.3771 10.0013 11.7434 9.14175 11.8349 8.23277H9.97354C9.88698 9.64653 9.38099 11.0024 8.5202 12.1272ZM9.97354 7.33398H11.8349C11.7434 6.425 11.3771 5.56546 10.785 4.86976C10.1928 4.17405 9.40286 3.67518 8.5202 3.43951C9.38099 4.56435 9.88698 5.92022 9.97354 7.33398ZM9.07295 7.33398H5.65395C5.75561 5.88577 6.36032 4.51865 7.36345 3.46917C8.36658 4.51865 8.97129 5.88577 9.07295 7.33398ZM4.75336 8.23277C4.83987 9.64654 5.34587 11.0024 6.20671 12.1272C5.32404 11.8916 4.53407 11.3927 3.94193 10.697C3.34979 10.0013 2.98355 9.14175 2.89196 8.23277H4.75336ZM4.75336 7.33398C4.83987 5.92021 5.34587 4.56432 6.20671 3.43951C5.32404 3.67518 4.53407 4.17405 3.94193 4.86976C3.34979 5.56546 2.98355 6.425 2.89196 7.33398H4.75336Z" fill="#373D4D" />
                      </svg></div>Dashboard language</InputLabel>
                      <FormControl sx={{ m: 0, width: 160 }}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          placeholder='Language'
                          className='select-item-list email-field'
                          value={Language}
                          onChange={handleSelectLanguage}
                        >
                          <MenuItem value={'En'}>English</MenuItem>
                          <MenuItem value={'Fn'}>French</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <Box sx={{ width: '100%' }} className="profile_tab" style={{ display: 'none' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                      <Tab label="Profile" {...a11yProps(0)} />
                      <Tab label="Notifications" {...a11yProps(1)} />
                      <Tab label="Profile Image" {...a11yProps(2)} />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <div className="profile-tab-update-outer display-2">
                      <div className="profile-tab-update-inner">
                        <Typography variant="h6">Apz pay</Typography>
                        <div><Typography variant="p">no-reply@apzex.com</Typography></div>
                        <Button onClick={handleOpen2}>Change name</Button>
                      </div>
                      <div className="profile-tab-update-inner">
                        <Typography variant="h6">Two-factor authentication</Typography>
                         <div>
                          <Button onClick={handleDisableButton}>
                            {isDisabled ? 'Enable' : 'Disable'} Button
                          </Button>

                          <Button disabled={isDisabled} style={{ marginLeft: '10px' }}>
                            I am {isDisabled ? 'Disabled' : 'Enabled'}
                          </Button>
                        </div>
                        {/* <Button>Change</Button> */}
                        <div></div>
                      </div>
                      <div className="profile-tab-update-inner">
                        <Typography variant="h6">Password</Typography>
                        <Button onClick={handleOpen}>Change password</Button>
                        <div></div>
                      </div>
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <div className="profile-tab-update-outer display-2">
                      <div className="profile-tab-update-inner">
                        <Typography variant="h6">Apz pay</Typography>
                        <div><Typography variant="p">no-reply@apzex.com</Typography></div>
                        <Button>Change name</Button>
                      </div>
                      <div className="profile-tab-update-inner">
                        <Typography variant="h6">Two-factor authentication</Typography>
                        <Button>Change</Button>
                        <div></div>
                      </div>
                      <div className="profile-tab-update-inner">
                        <Typography variant="h6">Password</Typography>
                        <Button>Change password</Button>
                        <div></div>
                      </div>
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <div className="profile-tab-update-outer display-2">
                      <div className="profile-tab-update-inner">
                        <Typography variant="h6">Apz pay</Typography>
                        <div><Typography variant="p">no-reply@apzex.com</Typography></div>
                        <Button>Change name</Button>
                      </div>
                      <div className="profile-tab-update-inner">
                        <Typography variant="h6">Two-factor authentication</Typography>
                        <Button>Change</Button>
                        <div></div>
                      </div>
                      <div className="profile-tab-update-inner">
                        <Typography variant="h6">Password</Typography>
                        <Button >Change password</Button>
                        <div></div>
                      </div>
                    </div>
                  </CustomTabPanel>
                </Box>

                <Box className="profile_tab">
                    <div className="profile-tab-update-outer display-2">
                      <div className="profile-tab-update-inner display-1 add-edit">
                      <div>
                      <Typography variant="h6">Apz pay</Typography>
                        <div><Typography variant="p">no-reply@apzex.com</Typography></div>
                        <Button onClick={handleOpen2}>Change name</Button>
                      </div>
                      <div className='edit_btn'><Link to="/editProfile">EDIT</Link></div>
                      {/* <CreateIcon /> */}
                      
                      </div>
                      <div className="profile-tab-update-inner">
                        <Typography variant="h6">Two-factor authentication</Typography>
                        <div className='enable_btn'>
                          <Button onClick={handleDisableButton}  className={isDisabled ? 'disabled-button' : 'enabled-button'}>
                            {isDisabled ? 'Disable' : 'Enable'}
                          </Button>
                        </div>
                        {/* <Button>Change</Button> */}
                        <div></div>
                      </div>
                      <div className="profile-tab-update-inner">
                        <Typography variant="h6">Password</Typography>
                        <Button onClick={handleOpen}>Change password</Button>
                        <div></div>
                      </div>
                    </div>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className='item-list-table'>
                <h2>Active Sessions</h2>
                <TableContainer >
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" className="table-bold-item">Created</TableCell>
                        <TableCell align="left" className="table-bold-item">Expires</TableCell>
                        <TableCell align="left" className="table-bold-item">Source</TableCell>
                        <TableCell align="left" className="table-bold-item">Ip</TableCell>
                        <TableCell align="left" className="table-bold-item">Platform</TableCell>
                        <TableCell align="center" className="table-bold-item">Device</TableCell>
                        <TableCell>Placement</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {List?.map((List, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <div className='flex-line-gap'>
                              <div className="table-bold-item f-500"> {List.date}</div>
                              <div className='td-grey-text'>
                                {List.time}
                                {List.gmt}
                              </div>
                              <Button className="session-btn">Current session</Button>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className='flex-line-gap'>
                              <div className="table-bold-item f-500">
                                {List.exDate}
                              </div>
                              <div className='td-grey-text'>
                                {List.exTime}
                                {List.exGmt}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className="table-bold-item f-500">
                            {List.source}
                          </TableCell>
                          <TableCell className="table-bold-item f-500">{List.ip}</TableCell>
                          <TableCell>
                            <div className='flex-line-gap'>
                              <div className="table-bold-item f-500">{List.platform}</div>
                              <div className='td-grey-text'>{List.chrome}</div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className='flex-line-gap table-bold-item f-500'>
                              <div>{List.dtype}</div>
                              <div>{List.dmarker}</div>
                              <div>{List.dmodel}</div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className='country d-flex'>
                              <div style={{ padding: '5px' }}><img src={List.countryFlag} alt={countryFlag} /></div>
                              <div>
                                <div className='flex-line-gap table-bold-item f-500'>
                                  {List.country}
                                </div>
                                <div className='td-grey-text'>{List.city}</div>
                              </div>
                            </div>
                          </TableCell>

                        </TableRow>
                      ))
                      }

                    </TableBody>


                  </Table>
                </TableContainer>
                <>
                  <div className='add-false-content' sx={{ display: 'none' }}>
                    There are no items yet
                  </div>
                </>
              </div>

              <TablePagination sx={{ display: 'none' }}
                component="div"
                count={100}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>

          </Grid>
        </Box>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div >
              <div className='display-2'>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  <strong>Change password</strong>
                </Typography>
                <div onClick={handleClose} className='cursor'>
                  <HighlightOffIcon />
                </div>
              </div>

              <div className='email-filed margin-top' style={{ width: '320px', margin: '20px auto' }}>
                <label className='display-1'>Old Password </label>
                <FormControl sx={{ width: '100%' }} variant="outlined">

                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Enter Old Password'
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  // label="Password"
                  />
                </FormControl>
              </div>
              <div className='email-filed margin-top' style={{ width: '320px', margin: '20px auto' }}>
                <label className='display-1'>New Password </label>

                <FormControl sx={{ width: '100%' }} variant="outlined">

                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Enter New Password'
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  // label="Password"
                  />
                </FormControl>
              </div>
              <div className='email-filed margin-top' style={{ width: '320px', margin: '20px auto' }}>
                <label className='display-1'>Confirm Password </label>

                <FormControl sx={{ width: '100%' }} variant="outlined">

                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Confirm Password'
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  // label="Password"
                  />
                </FormControl>
              </div>
              <div className="exteddata text-center margin-top">
                <Button onClick={handleClose}>
                  Change Password
                </Button>

              </div>
            </div>

          </Box>
        </Modal>
        <Modal
          open={open2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div >
              <div className='display-2'>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  <strong>Change Name</strong>
                </Typography>
                <div onClick={handleClose2} className='cursor'>
                  <HighlightOffIcon />
                </div>
              </div>

              <div className='email-filed margin-top' style={{ width: '320px', margin: '20px auto' }}>
                <label className='display-1'>New Name </label>
                <TextField id="outlined-basic"
                  placeholder='Enter New Name' variant="outlined" style={{ width: '100%' }} />
              </div>


              <div className="exteddata text-center margin-top">
                <Button onClick={handleClose2}>
                  Change Nmae
                </Button>

              </div>
            </div>

          </Box>
        </Modal>
      </Box>
    </div>
  )
}
