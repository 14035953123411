import React from 'react';
import TextField from '@mui/material/TextField';
import Logo from '../../../Images/logo.png';
import { Link } from 'react-router-dom'
import './Login.css';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import Google from '../../../Images/google-icon.png';

const SignUp = () => {
    return (
        <div className='login-full' style={{ height: '100vh', overflow: 'hidden' }}>
            <div className='login-bg'>
                <div className='logo-bar'>
                    <Link to="/"><img src={Logo} />
                    </Link>

                </div>
                <h1>Welcome to APZKYC</h1>
                <p>Already have an account? <Link style={{ marginLeft: "5px" }} to="/login">Log in</Link></p>
                <div className='textfield'>
                    <div className='email-filed'>
                        <label className='display-1'>Full name <HelpOutlinedIcon /></label>
                        <TextField fullWidth id="fullWidth" placeholder='John Smith' />
                    </div>
                    <div className='email-filed'>
                        <label className='display-1'>Work Email</label>
                        <TextField fullWidth id="fullWidth" placeholder='name@company.com' />
                    </div>
                    <div className='email-filed'>
                        <label className='display-1'>Full company name <HelpOutlinedIcon /></label>
                        <TextField fullWidth id="fullWidth" placeholder='Company name' />
                    </div>
                    <div className='submit-button cursor'>
                        <Link to="/activate">Continue</Link>
                    </div>
                    <div className='or-col'><span>or</span></div>

                    <div className='sign-up-google display-1'>
                        <div>
                            <img src={Google} />
                        </div>
                        <div>
                            <Link to="">Sign up with a corporate Google account</Link>
                        </div>
                    </div>
                    {/* <div className='privacy-note'>
                        <p>I warrant and represent that I am duly authorized to proceed with the
                            registration on behalf of the legal entity to which the email address (and
                            other relevant data, if any) specified hereby pertains (the "Company"). I
                            confirm that the Company fully acknowledges, accepts and consents to
                            APZKYC's <Link to="">Terms and Conditions</Link> and <Link>Privacy Notice</Link> </p>
                    </div> */}
                    <div className='copy-rght'>
                        <p>Apzkyc Ltd 2024. All rights reserved.</p>
                        <Link>Cookie preferences, </Link>
                        <Link>Privacy, </Link>
                        <Link>Terms.</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp
