import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Logo from '../../../Images/logo.png';
import { Link } from 'react-router-dom';
import './Login.css';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

const Login = () => {

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);


    return (
        <div className='signin-full contain-width'>
            <Box>
                <Grid container style={{ height: '100vh', alignItems: 'center' }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className='signin-left'>
                            <h2>Scale globally with the highest
                                pass rates on the market</h2>
                            <p>“After we signed with APZKYC and integrated their tool, we
                                managed to increase the speed of verification by more than 4
                                times. Concurrently, the number of our clients increased by
                                more than 3 times.”</p>
                            <h5>Join 2500+ companies</h5>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className='login-bg'>
                            <div className='logo-bar'>
                                <Link to="/"><img src={Logo} />
                                </Link>
                            </div>
                            <h1>Get started</h1>
                            <div className='textfield'>
                                <div className='email-filed'>
                                    <TextField inputProps={{ autoComplete: 'off' }} fullWidth id="fullWidth" placeholder='Business email address *' />
                                </div>
                                <div className='email-filed'>
                                    <FormControl sx={{ width: '100%' }} variant="outlined" inputProps={{ autoComplete: 'off' }}>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder='Password'
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>
                                <div className='submit-button cursor'>
                                    <Link to="/dashboard">Submit</Link>
                                </div>

                                <p>Don't have an account?<Link style={{ marginLeft: "5px" }} to="/signup">Sign Up</Link></p>
                                {/* <div className='privacy-note'>
                                    <p>By submitting the form, you agree that your personal data will be processed to
                                        provide you with the product demo, including contacting via email and phone, and for the purposes you agreed to above in accordance with the <Link>Privacy Notice</Link> </p>
                                </div> */}

                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default Login
