import React from 'react';
import { Link } from 'react-router-dom';
import './Login.css';
import '../../../Media.css'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Logo from '../../../Images/logo.png';

const CreatePassword = () => {

    const [showPassword, setShowPassword] = React.useState(false);
    const [hidePassword, setHidePassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickHidePassword = () => setHidePassword((show) => !show);




    return (
        <div className='register-full' style={{ height: '100vh', overflow: 'hidden' }}>
            <div className='contain-width'>
                <div className='login-bg'>
                    <div className='logo-bar'>
                        <Link to="/"><img src={Logo} />
                        </Link>

                    </div>
                    <h1>Create password</h1>
                    <div className='textfield'>
                        <div className='email-filed'>
                            <label>Password </label>
                            <FormControl sx={{ width: '97%' }} variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                        <div className='email-filed'>
                            <label>Confirm password</label>
                            <FormControl sx={{ width: '97%' }} variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={hidePassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickHidePassword}
                                                edge="end"
                                            >
                                                {hidePassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                        <div className='disclamier'>
                            <List>
                                <ListItem><HighlightOffOutlinedIcon /> 10 or more characters</ListItem>
                                <ListItem><HighlightOffOutlinedIcon /> Numbers & symbols</ListItem>
                                <ListItem><HighlightOffOutlinedIcon /> Mix of letters (upper and lower cases)</ListItem>
                            </List>
                        </div>
                        <div className='submit-button cursor'>
                            <Link to="/login">Create password</Link>
                        </div>
                        <div className='copy-rght'>
                            <p>Apzkyc Ltd 2024. All rights reserved.</p>
                            <Link>Cookie preferences, </Link>
                            <Link>Privacy, </Link>
                            <Link>Terms.</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreatePassword
