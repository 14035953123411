import React from 'react'
import coming from './Images/coming.jpg'
import Header from './Business/Components/Header/Header'
import Sidebar from './Business/Components/SideBar/Sidebar'
import { Box } from '@mui/material'
// import { useLocation } from 'react-router-dom'

function Comingsoon() {
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                {/* <CssBaseline /> */}
                {<Sidebar />}
                <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                    <div className='comingsoon'>

                        <img src={coming} alt="coming" />
                    </div>
                </Box>
            </Box>
            {/* <Sidebar /> */}

        </>

    )
}

export default Comingsoon
